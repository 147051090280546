// NOTE: the order of these styles DO matter

$primary-green: #A9ED53;
$dark-green: rgba(14, 117, 24, 0.988);

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $primary-green;
  color: $dark-green;
  border: 1px solid $primary-green;
  font-weight: 800;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $dark-green;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $dark-green;
}

// Will edit the text box container for the selected date
.DateInput {
  display: flex;
  width: 318px;
  color: #484848 !important;

  @media (min-width: 551px) and (max-width: 1100px) {
    width: 250px;
  }
}

// Will edit the text box itself for the selected date
.DateInput_input {
  font-weight: 800;
  text-align: center;
  font-style: normal;
  color: #484848 !important;
}

.DateInput_input__disabled, .DateInput__disabled, .SingleDatePickerInput__disabled {
  background: transparent;
  color: #484848 !important;
}

.DayPickerNavigation_button__default {
  border: 1px solid grey;
}

.SingleDatePickerInput__disabled {
  border: none;
  color: #484848 !important;
}

.CalendarMonth_caption strong {
  font-weight: normal !important;
}

input[type="text"]:disabled {
  color: #484848 !important;
}

.CalendarDay__blocked_calendar {
  position: relative;
}

.CalendarDay__blocked_calendar:after {
  content: "Schedule Filled";
  display: none;
  position: absolute;
  top: 110%;
  left: 10px;
  z-index: 50000;
  pointer-events: none;
  padding: 8px 10px;
  line-height: 15px;
  white-space: nowrap;
  text-decoration: none;
  text-indent: 0;
  font-size: 16px;
  font-weight: normal;
  color: white;
  background-color: black;
  border: 2px solid black;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.75;
}

.CalendarDay__blocked_calendar:focus {
  outline:1px dashed #E92C6C;
}

[aria-label]:hover:after, [aria-label]:focus:after {
  display: block;
}

.DayPicker_transitionContainer, .CalendarMonthGrid__horizontal {
  overflow: visible;
  width: initial !important;
}

.CalendarMonthGrid_month__hidden {
  display: none;
}
